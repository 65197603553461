<template>
  <section class="section-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-3 borders">
          <div class="section-title">
            <h2>Formation</h2>
          </div>
        </div>
        <div class="col-md-9 borders">
          <div class="row">
            <div class="col-md-12">
              <div class="content-item">
                <small>2006</small>
                <h3>BAC Scientifique</h3>
                <h4>Lycée Robert Schuman</h4>
                <p>Metz</p>
              </div>
              <div class="content-item">
                <small>2005</small>
                <h3>Attestation de Formation aux Premiers Secours</h3>
                <h4>Lycée Robert Schuman</h4>
                <p>Metz</p>
              </div>
              <div class="content-item">
                <small>2002</small>
                <h3>Brevet des collèges</h3>
                <h4>Collège André Malraux</h4>
                <p>Delme</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Education'
}
</script>

<style scoped lang="scss">

</style>
