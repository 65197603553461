<template>
  <section class="section-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-3 borders">
          <div class="section-title">
            <h2>Divers</h2>
          </div>
        </div>
        <div class="col-md-9 borders">
          <div class="row">
            <div class="col">
              <ul class="fa-ul fa-ul-code">
                <li><a href="https://www.inrs.fr/services/formation/publics/sauveteur-secouriste.html">Sauveteur Secouriste du Travail</a> depuis le 13 Octobre 2020</li>
                <li>Concepteur d’<span>applications</span> pour <span>Streamers professionnels</span></li>
                <li><span>Administrateur</span> de serveurs de jeu</li>
                <li>Concepteur amateur de <span>mods</span> et de <span>cartes</span> sur <span>Unreal Engine</span> et <span>Unity</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Other'
}
</script>

<style scoped lang="scss">
li > span {
  color: #ff5722;
  font-weight: 400;
}
</style>
